import { mapState } from 'vuex';
import KnAlert from '../../components/KnAlert.vue';
import KnFooter from '../../components/KnFooter.vue';
import KnNavbar from '../../components/KnNavbar.vue';

export default {
  name: 'AppLayout',
  components: {
    KnAlert,
    KnFooter,
    KnNavbar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['isLogin', 'isPOS']),
    backgroundColor() {
      if (this.isLogin) {
        return 'primary';
      } else if (this.isPOS) {
        return 'gray3';
      }
      return '';
    },
  },
};
