<template>
  <v-footer class="pt-1" color="gray1">
    <v-row class="px-md-15 pt-md-4 pb-md-10">
      <v-col
        class="text-center text-md-left grayoscuro--text text-caption"
        align-self="end"
      >
        Política de privacidad
      </v-col>
      <v-col
        class="text-center grayoscuro--text text-caption"
        align-self="start"
      >
        <span class="font-weight-bold">KinderPlus&reg; 2024</span><br />
        <span>Todos los derechos reservados</span>
      </v-col>
      <v-col
        class="text-center text-md-right grayoscuro--text text-caption"
        align-self="end"
      >
        Condiciones de uso
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
