<template>
  <div>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="KinderPlus Logo"
          class="shrink mr-2"
          contain
          :src="require('../../../assets/images/kinderplus_logo_redondo.png')"
          transition="scale-transition"
          width="50"
        />
      </div>
      <v-row align="center">
        <v-col
          class="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex"
          md="4"
          lg="3"
          xl="3"
        >
          <v-select
            v-if="allInstitutions"
            class="mx-4"
            v-model="selectedInstitution"
            hide-details
            dense
            :items="allInstitutions"
            item-text="nombre_comercial"
            item-value="id"
            return-object
            @input="setSelectedInstitution"
          />
          <div v-else class="d-none d-md-flex">
            {{ currentInstitutionName }}
          </div>
        </v-col>
        <v-spacer
          class="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex"
        ></v-spacer>
        <v-col md="4" lg="3" xl="3">
          <v-autocomplete
            v-model="selectedLink"
            :loading="loading"
            :items="items"
            append-icon="mdi-magnify"
            :search-input.sync="searchLink"
            dense
            clearable
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="A donde quieres ir?"
            solo-inverted
            item-text="title"
            item-value="to"
            :menu-props="{ closeOnContentClick: true }"
            @input="goToLink"
          />
        </v-col>
      </v-row>

      <v-btn text @click="exit">
        <span class="text-capitalize mr-2">Salir</span>
      </v-btn>
      <v-btn text @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <!--<v-btn text>
        <span class=" text-capitalize mr-2">Cerrar</span>
        <v-icon>mdi-close</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-navigation-drawer
      class="primary"
      v-model="drawer"
      app
      temporary
      right
      dark
    >
      <v-list>
        <v-list-item>
          <v-list-item-content></v-list-item-content>
          <v-list-item-action class="pr-6">
            <v-icon @click="drawer = false">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item class="d-md-none d-lg-none d-xl-none">
          <v-list-item-content>
            <template v-if="allInstitutions">
              <div class="d-flex align-center">
                <v-select
                  v-model="selectedInstitution"
                  hide-details
                  :items="allInstitutions"
                  item-text="nombre_comercial"
                  item-value="id"
                  return-object
                  @input="setSelectedInstitution"
                />
              </div>
            </template>
            <template v-else> {{ currentInstitutionName }} </template>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense>
        <template v-for="(item, index) in allowedMenu">
          <v-list-group
            v-if="item.children"
            :key="item.title"
            class="pl-6"
            color="white"
          >
            <template v-slot:activator>
              <v-list-item-title class="text-subtitle-2">{{
                item.title
              }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="child in item.children"
              :key="child.title"
              link
              :to="{ name: child.to }"
              @click="trackRoute(item.title + ' - ' + child.title)"
            >
              <v-list-item-content class="pl-6">
                <v-list-item-title class="text-subtitle-2">
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="pr-6">
                <v-icon color="white">mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="`${item.title}+${index}`"
            link
            :to="{ name: item.to }"
            @click="trackRoute(item.title)"
          >
            <v-list-item-content class="pl-6">
              <v-list-item-title class="text-subtitle-2">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="pr-6">
              <v-icon color="white">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-list-group v-if="showPOS" class="pl-6" color="white">
          <template v-slot:activator>
            <v-list-item-title class="text-subtitle-2"
              >Punto de venta</v-list-item-title
            >
          </template>
          <v-list-item
            v-for="child in pos"
            :key="child.title"
            link
            :to="{ name: child.to }"
          >
            <v-list-item-content class="pl-6">
              <v-list-item-title class="text-subtitle-2">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="pr-6">
              <v-icon color="white">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>

        <v-divider class="my-2" dark />
        <v-list-item
          v-for="item in allowedAdminMenu"
          :key="item.title"
          link
          :to="{ name: item.to }"
        >
          <v-list-item-content class="pl-6">
            <v-list-item-title class="text-subtitle-2">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="item.icon" class="pr-6">
            <v-icon color="white">mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item link @click="exit">
          <v-list-item-content class="pl-6">
            <v-list-item-title class="text-subtitle-2">
              Cerrar sesión
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action></v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import mixpanel from 'mixpanel-browser';
import { UserTypes } from '../helpers/permissionContants';
import { mainMenu, configMenu, posMenu } from '../helpers/navigationLinks';

export default {
  data() {
    return {
      drawer: false,
      group: null,
      selectedInstitution: null,
      pos: posMenu,
      loading: false,
      selectedLink: null,
      searchLink: null,
      items: [],
    };
  },
  computed: {
    ...mapState(['userProfile', 'userData', 'currentInstitution']),
    ...mapGetters([
      'currentInstitutionName',
      'nameUserLoggedIn',
      'isLoggedIn',
      'allInstitutions',
    ]),
    allowedMenu() {
      let menuCopy = mainMenu;
      menuCopy = menuCopy.map((item) => {
        let newItem = { ...item };
        if (newItem.children) {
          newItem.children = item.children.filter((c) =>
            c.show.includes(this.userProfile)
          );
        }
        return newItem;
      });
      // console.log('menuCopy', { menuCopy });
      return menuCopy.filter((item) => item.show.includes(this.userProfile));
    },
    allowedAdminMenu() {
      return configMenu.filter((item) => item.show.includes(this.userProfile));
    },
    navigationSearch() {
      return [...this.allowedMenu, ...this.allowedAdminMenu];
    },
    showPOS() {
      return (
        this.userProfile === UserTypes.SUPERADMIN ||
        this.userProfile === UserTypes.KEYSTONE_ADMIN_EMPLOYEE ||
        this.userProfile === UserTypes.ACCOUNTING_EMPLOYEE ||
        this.userProfile === UserTypes.ACCOUNTING_ASSISTANT_EMPLOYEE
      );
    },
  },
  watch: {
    searchLink(value) {
      value && value !== this.selectedLink && this.filterLink(value);
    },
  },
  mounted() {
    if (this.allInstitutions) {
      this.selectedInstitution = this.currentInstitution;
    }
  },
  methods: {
    ...mapMutations(['setMixpanel']),
    ...mapActions(['logout', 'setCurrentInstitution']),
    exit() {
      this.$router.replace({ name: 'login' });
      this.logout();
    },
    trackRoute(clickedRoute) {
      // console.log('Track route', clickedRoute);
      mixpanel.track('Navegación', { ruta: clickedRoute });
    },
    setSelectedInstitution() {
      this.setCurrentInstitution(this.selectedInstitution);
    },
    filterLink(v) {
      this.loading = true;
      // Simulated ajax query
      let results = [];
      setTimeout(() => {
        if (v && v !== '') {
          this.navigationSearch.forEach((link) => {
            if (link.children && link.children.length) {
              results.push(
                ...link.children.filter((c) => {
                  return c.title.toLowerCase().includes(v.toLowerCase());
                })
              );
            } else if (link.title.toLowerCase().includes(v.toLowerCase())) {
              results.push(link);
            }
          });

          this.items = [...results];
        } else {
          this.items = [];
        }
        this.loading = false;
      }, 500);
    },
    goToLink() {
      // console.log(this.$route);

      if (
        this.selectedLink &&
        this.selectedLink !== '' &&
        this.$route.name !== this.selectedLink
      ) {
        const link = this.selectedLink;
        this.clearSearch();
        this.$router.replace({ name: link });
      }
    },
    clearSearch() {
      this.searchLink = null;
      this.selectedLink = null;
      this.items = [];
    },
  },
};
</script>

<style></style>
